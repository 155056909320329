<!-- 留言组件 -->
<template>
<div>
  <!-- content -->
  <div class="content">
    <!-- 表单区域 -->
    <div class="message_area">
      <el-form :model="message" :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm">
        <div class="temp">
          <el-form-item label="昵称" prop="visitorName" >
            <el-input v-model="message.visitorName" placeholder="请输入您的昵称" class="name"></el-input>
          </el-form-item>
          <el-form-item label="邮箱" prop="visitorEmail">
            <el-input v-model="message.visitorEmail" placeholder="请输入您的邮箱（选填）" class="email"></el-input>
          </el-form-item>
        </div>
        <el-form-item label="内容" prop="content">
          <el-input type="textarea"  :rows="5" v-model="message.content" placeholder="请输入留言内容" class="textarea"></el-input>
        </el-form-item>
        <el-form-item class="button" >
            <el-button type="primary" size="" @click="submitForm('ruleForm')" class="liuyana">发表留言</el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- 循环展示评论信息 -->
    <div class="noMessage" v-if="this.comments.length == 0">
        没有留言
    </div>
    <div v-for="(item, i) in comments" :key="i" class="author-title reply-father">
      <div class="messageWrapper">
        <!-- 评论中的头像 -->
        <el-avatar class="header-img" :size="40" :src="item.avatar"></el-avatar>
        <!-- 主评论的名字和日期 -->
        <div class="author-info">
          <span class="author-name"> 
              <span v-if="item.visitorName=='林小wu'" style="color:rgb(240,192,210);">{{ item.visitorName }}</span>
              <span v-else>{{ item.visitorName }}</span>
          </span>
          <span class="author-time">{{ item.creTime }}</span>
        </div>
        <!-- 主评论右边的回复 -->
        <div class="icon-btn">
          <span @click="showReplyInput(i, item.name, item.id)">
              <i class="iconfont el-icon-s-comment message_icon" title="回复"></i>{{ item.commentNum }}</span>
              <!-- <i class="iconfont el-icon-caret-top"></i>{{ item.like }} -->
        </div>
        <!-- 主评论的内容 -->
        <div class="talk-box">
          <p>
            <span class="reply">{{ item.content }}</span>
          </p>
        </div>
  
        <!-- 回复内容区 -->
        <div class="reply-box">
          <!-- 循环展示回复的内容 -->
          <div v-for="(reply, j) in item.sonList" :key="j" class="author-title">
            <el-avatar class="header-img" :size="40" :src="reply.avatar"></el-avatar>
            <!-- 回复的名字和日期 -->
            <div class="author-info">
              <span class="author-name">
                  <span v-if="reply.visitorName=='林小wu'" style="color:rgb(190,150,180);">{{ reply.visitorName }}</span>
                  <span v-else>{{ reply.visitorName }}</span>
              </span>
              <span class="author-time">{{ reply.creTime }}</span>
            </div>
            <!-- 回复右边的点赞数和回复按钮 -->
            <!-- <div class="icon-btn">
              <span @click="showReplyInput(i, reply.from, reply.id)"><i
                  class="iconfont el-icon-s-comment"></i>{{ reply.commentNum }}</span>
              <i class="iconfont el-icon-caret-top"></i>{{ reply.like }}
            </div> -->
            <!-- 回复内容详情 -->
              <div class="talk-box">
                <p>
                  <span class="reply">回复 {{ reply.parentName }}：</span>
                  <span class="reply">{{ reply.content }}</span>
                </p>
              </div>
            <div class="reply-box"></div>
          </div>
        </div>
      </div>
    </div>


    <!-- 回复dialog -->
    <div class="shadow" v-if="showDialog"></div>
    <div class="replayDialog" v-if="showDialog" >
      <div class="title">回复留言</div>
      <el-form :model="message" :rules="rules" ref="ruleForm" label-width="60px" class="demo-ruleForm dialog_form">
        <el-form-item label="昵称" prop="visitorName">
          <el-input v-model="message.visitorName" placeholder="请输入您的昵称"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="visitorEmail">
          <el-input v-model="message.visitorEmail" placeholder="请输入您的邮箱（选填）"></el-input>
        </el-form-item>
        <el-form-item label="内容" prop="content">
          <el-input type="textarea" :rows="5" v-model="message.content" placeholder="请输入留言内容"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="cancel" size="mini">取 消</el-button>
          <el-button type="primary" @click="save" size="mini">确 定</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</div>
</template>


<script>
import * as sysTool from '../assets/systools.js' ; 
export default {
  name : 'LVMessage' , 
  data(){
    return {
      // 留言表单
      message: {
        visitorName: '',
        visitorEmail: '',
        content: '' , 
        visitorIp:'' , 
        visitorArea:'',
        parentId:'' , 
        browser: '',
        os: ''  , 
        url:''
      },
      // 表单验证
      rules: {} , 
      // 留言items
      messageItem:[] ,
      // 显示回复框
      showDialog: false,  
      replyComment: '',  // 回复内容
      // 留言JSON数据
      comments: [
        {
          name: 'Lana Del Rey',
          id: 19870621,
          headImg: 'https://ae01.alicdn.com/kf/Hd60a3f7c06fd47ae85624badd32ce54dv.jpg',
          comment: '我发布一张新专辑Norman Fucking Rockwell,大家快来听啊',
          time: '2019年9月16日 18:43',
          commentNum: 2,
          like: 15,
          inputShow: false,
          reply: [
            {
              from: 'Taylor Swift',
              fromId: 19891221,
              fromHeadImg: 'https://ae01.alicdn.com/kf/H94c78935ffa64e7e977544d19ecebf06L.jpg',
              to: 'Lana Del Rey',
              toId: 19870621,
              comment: '我很喜欢你的新专辑！！',
              time: '2019年9月16日 18:43',
              commentNum: 1,
              like: 15,
              inputShow: false
            },
            {
              from: 'Ariana Grande',
              fromId: 1123,
              fromHeadImg: 'https://ae01.alicdn.com/kf/Hf6c0b4a7428b4edf866a9fbab75568e6U.jpg',
              to: 'Lana Del Rey',
              toId: 19870621,
              comment: '别忘记宣传我们的合作单曲啊',
              time: '2019年9月16日 18:43',
              commentNum: 0,
              like: 5,
              inputShow: false

            }
          ]
        }
      ]
    } 
  } , 
  props:["article_id"] , 
  methods:{
    // 提交留言
    submitForm(formName) {
      if (!this.message.content || !this.message.visitorName) {
        this.$message({
          message: "请填写昵称和内容",
          type: "warning"
        });
        return;
      }
      if(this.message.visitorName == "林小wu"){
          if(!this.$store.state.userInfo){
            this.$message({
            message: "不可以使用博主的昵称哦，换一个试试呢？",
            type: "warning"
            });
            return  ;
          }
      }
      var os = sysTool.GetOs() ; 
      this.message.articleId = this.article_id ; 
      this.message.visitorIp = sessionStorage.getItem('ip') ; 
      this.message.visitorArea = sessionStorage.getItem('area') ; 
      this.message.browser = sysTool.GetCurrentBrowser() ; 
      this.message.os = os.name ; 
      // 获取当前页面的url
      let url = window.location.href ; 
      this.message.url = url ; 
      this.$axios.post('/addMessage' , this.message  , {showLoading:true}, {

      }).then(res => {
        this.getMessages() ; 
        this.$message.success("留言成功！") ; 
        this.message = {} ; 
      })
    },
    getMessages() {
      // 如果是留言的话，就写死=0
      // 如果是 评论，则需要设置 当前被评论的模块的id作为articleId
      let articleId = 0;
      this.$axios.get("/getMessage/" + this.article_id).then(res => {
        // console.log(res);
        this.comments = res.data.data;
        // this.messageItem.content
        // console.log(this.comments);
      })
    },
    // dialog取消
    cancel() {
      this.showDialog = false;
      this.message = {};
    },
    // 保存回复
    save() {
      if (!this.message.content || !this.message.visitorName) {
        this.$message({
          message: "请填写昵称和内容",
          type: "warning"
        });
        return;
      }
      if(this.message.visitorName == "林小wu"){
          if(!this.$store.state.userInfo){
            this.$message({
            message: "不可以使用博主的昵称哦，换一个试试呢？",
            type: "warning"
            });
            return  ;
          }
      }
      // console.log(this.message);
      // 如果是评论的话，在 save的时候要注意设置 当前模块的id为 foreignId。也就是  message.foreignId = 模块id
      this.message.visitorIp = sessionStorage.getItem('ip') ; 
      this.message.visitorArea = sessionStorage.getItem('area') ; 
      var os = sysTool.GetOs() ; 
      this.message.browser = sysTool.GetCurrentBrowser() ; 
      this.message.os = os.name ; 
      // 获取当前页面的url
      let url = window.location.href ; 
      this.message.url = url ; 
      this.$axios.post('/addMessage' , this.message , {showLoading:true}, {

      }).then(res => {
        this.getMessages() ; 
        this.$message.success("回复成功！") ; 
        this.message = {}
        this.showDialog = false;
      })
    },
    getLogos(os,browser){
      let osLogo = '' ; 
      let browserLogo = ' ' ;
      this.message.browser = sysTool.GetCurrentBrowser() ; 
      this.message.os = os.name ; 
      if (os === '') {
        
      }
    } , 


    // 点击主评论和子评论右边的回复按钮，对相应的人进行回复
    showReplyInput(i, name, id) {
      console.log(i + "-" + name + "-" + id);
      this.message.parentId = id;
      this.message.articleId = this.article_id;
      this.showDialog = true;
    },
    // 点击回复显示回复输入框
    _inputShow(i) {
      return this.comments[i].inputShow
    },
  } , 
  created(){
    this.getMessages() ; 
  }
}
</script>


<style scoped>

/* 留言item开始 */
.author-title:not(:last-child) {
  border-bottom: 1px solid rgba(178, 186, 194, 0.3);
}

.author-title {
  padding: 10px;
}

.author-title .header-img {
  display: inline-block;
  vertical-align: top;
}

.author-title .author-info {
  display: inline-block;
  margin-left: 5px;
  width: 60%;
  height: 40px;
  line-height: 20px;
}

.author-title .author-info>span {
  display: block;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.author-title .author-info .author-name {
  color: #52bfd5;
  font-size: 1.6rem;
  font-weight: 500;
}

.author-title .author-info .author-time {
  font-size: 13px;
  color: #888;
}

.author-title .icon-btn {
  width: 30%;
  padding: 0 !important;
  float: right;
}

@media screen and (max-width: 1200px) {
  .author-title .icon-btn {
    width: 20%;
    padding: 7px;
  }

.messageWrapper{
    margin: 1rem;
    padding: 2rem;
    /* display: flex;
    flex-wrap: wrap;
    justify-content: baseline; */
    border-radius: 5px;
    box-shadow: 1px 4px 15px rgb(125 147 178 / 32%);
    transition: all .3s;
    background: rgba(255, 255, 255, 0.7);
}
}

.author-title .icon-btn>span {
  cursor: pointer;
}

.author-title .icon-btn .iconfont {
  margin: 0 5px;
}

.author-title .talk-box {
  margin: 0 50px;
  word-wrap: break-word;
}

.author-title .talk-box>p {
  margin: 0;
}

.author-title .talk-box .reply {
  font-size: 13px;
  color: rgb(90, 87, 87);
}

.author-title .reply-box {
  margin: 10px 0 0 50px;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.6);
}
/* 留言item结束 */




@media all and (min-width:850px) {
.messageWrapper{
    margin: 1rem;
    padding: 1rem;
    /* display: flex;
    flex-wrap: wrap;
    justify-content: baseline; */
    border-radius: 5px;
    box-shadow: 1px 4px 15px rgb(125 147 178 / 32%);
    transition: all .3s;
    background: rgba(255, 255, 255, 0.7);
}
  .message_icon{
    color: rgb(82,191,213);
    font-size: 1.4rem;
    padding-left: 70%;
  }
  .content{
    width: 50vw;
    /* min-height: 600px; */
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    padding: 20px 30px;
    margin: 0 auto;
    margin-top: 5rem;
    background: rgba(255, 255, 255, 0.5);
    margin-bottom: 60px;
    padding-bottom: 8rem;
    border-radius: 5px;
  }

/* 表单区域 */
  .content .message_area{
    width: 98%;
    border-radius: 5px;
    margin: 0 auto;
    margin-top: 6rem;
    background: rgba(255, 255, 255, 0.5);
    padding-top: 2rem;
    font-size: 1.2rem;
    border: 1px solid rgb(235, 232, 232);
    border-left: 3px solid rgb(75,174,194);
    border-right: 3px solid rgb(209, 63, 124);
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    padding-bottom: 5rem;
    margin-bottom: 5rem;
  }
  .liuyana{
    position: absolute;
    width: 30vw;
    background-color: rgb(82,191,213);
    background-image: linear-gradient(-45deg, rgb(82,191,213),rgb(82,213,128) ); 
    border: none;
    /* margin-left: 3%; */
  }

  .demo-ruleForm{
      width: 70%;
  }
  /* .content .message_area .temp{
    display: flex;
  }
  .content .message_area .name,
  .content .message_area .email {
    width: 100%;
  }
  .content .message_area .textarea{
    width: 100%;
  } */

  /* .content .message_items .items_wrap .son_message{
    width: 70rem;
    background-color:rgba(255, 255, 255, 0.5);; 
    padding: 1rem ;
    border-radius: .5rem;
  }
  .content .message_items .items_wrap .message_bottom .cre_time{
    margin-left: 55rem;
  } */

/* dialog区域 */

  .replayDialog{
    width: 60rem;
    position: fixed;
    top: 28vh;
    left: 35vw;
    z-index: 10;
    background: #fff;
    padding: 3rem;
    padding-bottom: 0;
    border-radius: .7rem;
  }
  .replayDialog .title{
    font-size: 1.6rem;
    font-weight: 550;
    border-bottom: 1px solid #eee;
    padding-bottom: 1rem;
  }
  .replayDialog .dialog_form{
    margin-top: 2rem;
  }

}
@media all and (max-width:850px) {
  .message_icon{
    color: rgb(82,191,213);
    font-size: 1.4rem;
    padding-left: 40%;
  }
  .content{
    width: 96vw ; 
    /* background-color: pink; */
    /* border: 1px solid pink; */
    margin: 0 auto;
    /* margin-top: 4rem; */
    margin-bottom: 10rem;
    background: rgba(255, 255, 255, 0.5);
    padding-top: 1rem;
    padding-bottom: 10rem;
    border-radius: 5px;
  }

/* 表单区域 */
  .content .message_area{
    width: 98%;
    border-radius: 5px;
    margin: 0 auto;
    margin-top: 6rem;
    background: rgba(255, 255, 255, 0.5);
    padding-top: 2rem;
    font-size: 1.2rem;
    border: 1px solid rgb(235, 232, 232);
    border-left: 3px solid rgb(75,174,194);
    border-right: 3px solid rgb(209, 63, 124);
    box-sizing: border-box;
    margin-bottom: 5rem;
  }

  /* .content .message_area .name,
  .content .message_area .email {
    width: 60vw;
  }
  .content .message_area .textarea{
    width: 60vw;
  } */
  .demo-ruleForm{
      width: 90%;
  }
.content .message_area .button{
    width: 93vw;
    /* background: pink; */
}
  .liuyana{
    width: 60vw;
    background-color: rgb(82,191,213);
    background-image: linear-gradient(-45deg, rgb(82,191,213),rgb(82,213,128) ); 
    border: none;
    /* margin-left: 24%; */
  }

  .content .message_items .items_wrap .item_content p {
    width: 70vw;
    word-wrap: break-word;
  }
  .content .message_items .items_wrap .son_message{
    width: 60vw;
    background-color: rgba(255, 255, 255, 0.5);; 
    padding: 1rem ;
    border-radius: .5rem;
    word-wrap: break-word;
  }
  .content .message_items .items_wrap .message_bottom .cre_time{
    margin-left: 8rem;
  }

/* dialog区域 */

  .replayDialog{
    width: 80vw;
    position: fixed;
    top: 15vh;
    left: 1.7vw;
    z-index: 10;
    background: #fff;
    padding: 3rem;
    padding-bottom: 0;
    border-radius: .7rem;
  }
  .replayDialog .title{
    font-size: 1.6rem;
    font-weight: 550;
    border-bottom: 1px solid #eee;
    padding-bottom: 1rem;
  }
  .replayDialog .dialog_form{
    margin-top: 2rem;
  }
}


/* message区域 */
  .content .message_items{
    width: 98%;
    display: flex; 
    /* padding: 20px ; */
    margin: 0 auto;
    margin-top: 3rem;
    padding-top: 3rem;
    font-size: 1.3rem;
    border-top: 1px solid #eee;
  }
  .content .message_items .avatar_wrap{
    text-align: center; 
    flex: 1 ;
  }
  .content .message_items .avatar_wrap .avatar{
    width: 6rem; 
    height: 6rem; 
    border-radius: 50% ;
  }
  .content .message_items .items_wrap{
    padding: 0 10px; 
    flex: 9 ;
  }
  .content .message_items .items_wrap .visitor_name{
    font-weight: 500;
    font-size: 1.4rem;
    color: rgb(82,191,213);
  }
  .content .message_items .items_wrap .item_content{
    padding: 10px 0; 
    color: rgb(119, 115, 115) ;
  }
  .content .message_items .items_wrap .message_bottom{
    margin-top: 1rem;
    color: #888; 
  }
  .content .message_items .items_wrap .message_bottom .replay_btn{
    cursor: pointer;
    transition: all .3s;
  }
  .content .message_items .items_wrap .message_bottom .replay_btn:hover{
    color: rgb(82,191,213);
  }

  .shadow{
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, .4);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
  }
  .el-button--primary{
    background-color: rgb(82,191,213);
    border: none;
  }
  .up{
    background-color: rgba(255, 255, 255, 0.5);  
  }

  .noMessage{
    width: 98%;
    height: 30rem;
    background-color: rgba(248, 248, 248 , .5);
    line-height: 30rem;
    text-align: center;
    margin: 0 auto;
    margin-top: 30px;
    border-radius: 5px;
    font-size: 1.3rem;
    color: rgb(124, 124, 124);
  }

</style>